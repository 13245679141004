// 3rd party
import React from "react"
import styled from "styled-components"

// constants
import {transition} from "../../../constants/css"
import Arrow from "../../icons/arrow"

// components
import WorkThumb from "./workThumb"

function WorkThumbsLarge({leftImages, rightImages, items, imageNum, handlePrev, handleNext, isActive}) {
  let currentItem = items[imageNum]
  let {height, width} = (currentItem?.type === "image" && currentItem?.image?.mediaDetails) || {height: 1, width: 50}
  let aspectRatio = width / height
  let maxImageNums = 3
  let maxIndex = maxImageNums + 1 + 1

  return (
    <div className="from-tablet-m">
      {imageNum > 0 && (
        <ArrowWrapperLeft onClick={() => handlePrev()} isVisible={isActive}>
          <Arrow direction="left" />
        </ArrowWrapperLeft>
      )}
      <Left aspectRatio={aspectRatio}>
        <Inner>
          {leftImages.map((item, index) => {
            return (
              index <= maxIndex && (
                <WorkThumb {...{index, item}} isVisible={index <= maxImageNums} onClick={handlePrev} side="left" key={item.id} />
              )
            )
          })}
        </Inner>
      </Left>
      {imageNum + 1 < items.length && (
        <ArrowWrapperRight onClick={() => handleNext()} isVisible={isActive}>
          <Arrow />
        </ArrowWrapperRight>
      )}
      <Right aspectRatio={aspectRatio}>
        <Inner>
          {rightImages.map((item, index) => {
            return (
              index <= maxIndex && (
                <WorkThumb {...{index, item}} isVisible={index <= maxImageNums} onClick={handleNext} side="right" key={item.id} />
              )
            )
          })}
        </Inner>
      </Right>
    </div>
  )
}

const ArrowWrapper = styled.button`
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  transition: 0.25s;
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
  visibility: ${({isVisible}) => (isVisible ? "visible" : "hidden")};
`

const ArrowWrapperLeft = styled(ArrowWrapper)`
  left: 0;
`

const ArrowWrapperRight = styled(ArrowWrapper)`
  right: 0;
`

const ThumbsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  transition: ${transition.gallery}ms;
`

const Left = styled(ThumbsWrapper)`
  right: min(calc(15px + 50% + 0.5 * ${({aspectRatio}) => aspectRatio} * (100vh - 100px)), calc(15px + 100%));
`

const Right = styled(ThumbsWrapper)`
  left: min(calc(15px + 50% + 0.5 * ${({aspectRatio}) => aspectRatio} * (100vh - 100px)), calc(15px + 100%));
`

const Inner = styled.div`
  position: relative;
  height: 70px;
  width: 500px;
`

export default WorkThumbsLarge
