// 3rd party
import React, {useContext, useState} from "react"
import {CSSTransition} from "react-transition-group"
import styled from "styled-components"

// constants
import {transition} from "../../../constants/css"

// components
import WorkContentItem from "./workContentItem"
import WorkContentNav from "./workContentNav"

// context
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

function WorkContent({work, isModalOpen, setIsModalOpen, items, imageNum, setImageNum}) {
  let appState = useContext(GlobalStateContext)
  let [activeItem, setActiveItem] = useState()

  let extras = {
    "behind-the-scenes": [],
    sketch: [],
    description: work.description.content ? [work.description.content] : [],
    "making-of": [],
    thumbs: items.length > 1 ? items : []
  }
  let types = Object.keys(extras)
  work.extraContent.content?.forEach(item => {
    extras[item.type].push(item)
  })

  return (
    !appState.isInfoOpen && (
      <>
        {isModalOpen && <Overlay onClick={() => setIsModalOpen(false)} />}
        <CSSTransition timeout={transition.workContent} in={isModalOpen} appear={true} unmountOnExit classNames="shift">
          <WorkContentItem
            content={extras[activeItem]}
            {...{setIsModalOpen, work, activeItem, setImageNum, imageNum}}
            key={activeItem}
          />
        </CSSTransition>
        <WorkContentNav {...{activeItem, setActiveItem, isModalOpen, setIsModalOpen, types, extras}} />
      </>
    )
  )
}

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
`

export default WorkContent
