// 3rd party
import React, {useState, useEffect, useCallback} from "react"
import styled from "styled-components"

// util/ constants
import {getNumDenom} from "../../lib/utils"
import {size} from "../../constants/css"

// sc
import {Fallback} from "./sc/fallback"
import {StyledImage} from "./sc/styledImage"
import {getAspectRatio} from "./utils/getAspectRatio"

// components
import Spinner from "../general/spinner"

function SwipeImage({isContained, image, imageRef, cursor}) {
  // state
  let [isVisible, setIsVisible] = useState(false)

  const onLoadCallBack = useCallback(e => {
    setIsVisible(true)
  }, [])

  useEffect(() => {
    // https://stackoverflow.com/questions/59787642/nextjs-images-loaded-from-cache-dont-trigger-the-onload-event
    if (imageRef.current.complete) onLoadCallBack()
  }, [onLoadCallBack])

  let aspectRatio = getAspectRatio(image.mediaDetails)
  let [numerator, denominator] = getNumDenom(Math.round(aspectRatio * 100) / 100)

  let sizes = !isContained
    ? `(min-width: ${size.tabletM}px) 120vw,  200vw`
    : `(min-aspect-ratio: ${numerator}/${denominator}) ${aspectRatio * 100}vh, 100vw`

  return (
    <>
      <StyledImage
        $isVisible={isVisible}
        $isContained={isContained}
        $cursor={cursor}
        ref={imageRef}
        onLoad={onLoadCallBack}
        src={image.src}
        srcSet={image.srcSet}
        sizes={sizes}
      />
      {!isVisible && (
        <Fallback $aspectRatio={aspectRatio} $isVisible={!isVisible}>
          <Inner>
            <Center>
              <Spinner />
            </Center>
          </Inner>
        </Fallback>
      )}
    </>
  )
}

const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export default SwipeImage
