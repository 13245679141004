// 3rd party
import React from "react"

// components
import WorkThumbsLarge from "./workThumbsLarge"
import WorkThumbsMobile from "./workThumbsMobile"

function WorkThumbs({items, imageNum, isActive, ...props}) {
  let leftImages = items.slice(0, imageNum + 1).reverse()
  let rightImages = items.slice(imageNum, items.length)
  let aroundImages = items.slice(Math.max(imageNum - 6, 0), Math.min(items.length, imageNum + 7))
  let indexOfCurrentInAround = aroundImages.findIndex(x => x === items[imageNum])
  return (
    <>
      <WorkThumbsMobile images={aroundImages} {...{items, imageNum, indexOfCurrentInAround, ...props}} />
      <WorkThumbsLarge {...{leftImages, rightImages, items, imageNum, isActive, ...props}} />
    </>
  )
}

export default WorkThumbs
