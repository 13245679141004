import styled, {css} from "styled-components"
import {device} from "../../constants/css"

const BreadcrumbItem = styled.span`
  a {
    text-decoration: none;
  }
  ${({noWrap}) =>
    noWrap &&
    css`
      white-space: nowrap;
    `}
  //display: flex;
  width: max-content;
  font-size: 0.625rem;
  @media ${device.tabletM} {
    font-size: 0.875rem;
  }
  padding: 0 5px;
  &:first-of-type {
    padding: 0 5px 0 0;
  }
  cursor: ${({cursor}) => (cursor ? cursor : "default")};
`

export default BreadcrumbItem
