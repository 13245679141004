// 3rd party
import React, {useState, useRef} from "react"
import styled from "styled-components"
import useEventListener from "@use-it/event-listener" // because https://stackoverflow.com/questions/29069639/listen-to-keypress-for-document-in-reactjs

// components
import Breadcrumbs from "../breadcrumbs/breadcrumbs"
import TransitioningImage from "../splash/transitioningImage"
import Container from "../styled/container"
import Embed from "./embed"
import WorkContent from "./workContent/workContent"
import Lyric from "./lyric"
import WorkThumbs from "./workThumb/workThumbs"

// constants
import {device, sizes} from "../../constants/css"

function WorkSingle({work, mainProject, postType, medium, project, mediumParent}) {
  // state, ref
  let [imageNum, setImageNum] = useState(0)
  let [isModalOpen, setIsModalOpen] = useState(false)
  let [isActive, setIsActive] = useState(false)
  let wrapperRef = useRef()

  // data
  let child = postType === "project" && project
  let lyric = work.content?.lyric

  let hasGallery = work.content?.hasGallery && !!work.content.gallery.length
  let embeds = work.content?.embeddedMedia || []
  let hasLyric = work.content?.isLyric && !!lyric?.content
  let hasEmbed = work.content?.hasEmbeds && !!embeds?.length ? !!embeds[0].item && !hasLyric : false // array may have length, but first item might have no content
  let images = []

  if (hasGallery) {
    images = work.content?.gallery
  }

  let items = []
  let lyricObject = {lyric: lyric?.content, image: lyric?.image, embed: lyric?.embed, type: "lyric", id: "l" + 1}
  hasLyric && items.push(lyricObject)
  hasEmbed && embeds.forEach(({image, item}, i) => item && items.push({embed: item, image: image, type: "embed", id: "e" + i}))
  !hasLyric && images.forEach((image, i) => items.push({image: image, type: "image", id: "i" + i}))

  // functions
  function handler({key}) {
    if (key === "ArrowLeft") {
      handlePrev()
    } else if (key === "ArrowRight") {
      handleNext()
    } else if (key === "Escape") {
    }
  }

  useEventListener("keydown", handler)

  function handlePrev(offset = 1) {
    handleChange(-offset)
  }

  function handleNext(offset = 1) {
    handleChange(offset)
  }

  function handleChange(offset) {
    let index = imageNum + offset
    index = Math.min(index, items.length - 1)
    index = Math.max(index, 0)
    setImageNum(index)
  }

  let canSwipeLeft = imageNum > 0 && items.length > 1
  let canSwipeRight = imageNum + 1 < items.length

  return (
    <>
      <Breadcrumbs {...{postType, mainProject, child, medium, project, mediumParent}} current={work} />
      <Container>
        <MainContent ref={wrapperRef} onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)}>
          {items[imageNum]?.type === "image" && (
            <TransitioningImage
              image={items[imageNum].image}
              isActive={!isModalOpen}
              {...{handleNext, handlePrev, wrapperRef, canSwipeLeft, canSwipeRight}}
            />
          )}
          {items[imageNum]?.type === "lyric" && (
            <Lyric lyric={items[imageNum].lyric} title={work.title} embed={items[imageNum].embed} />
          )}
          {items[imageNum]?.type === "embed" && <Embed embed={items[imageNum].embed} />}
          <WorkThumbs {...{items, handleNext, handlePrev, handleChange, imageNum, isActive}} />
        </MainContent>
        <WorkContent {...{work, isModalOpen, setIsModalOpen, setImageNum, imageNum, items}} />
      </Container>
    </>
  )
}

const MainContent = styled.div`
  width: 100%;
  /*height: calc(100% - ${({isFull}) => (!isFull ? sizes.bottom.small + sizes.thumb + sizes.thumbMargin : 0)}px);
  @media ${device.tabletM} {
    width: calc(100% - ${({isFull}) => (!isFull ? sizes.attachedMargin : 0)}px);
    height: calc(100% - ${({isFull}) => (!isFull ? sizes.bottom.large : 0)}px);
  }*/ // old
  height: calc(100% - ${sizes.bottom.small + sizes.thumb + sizes.thumbMargin}px);
  @media ${device.tabletM} {
    width: calc(100% - ${sizes.attachedMargin}px);
    height: calc(100% - ${sizes.bottom.large}px);
  }

  position: relative;
`

export default WorkSingle
