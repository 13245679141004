// 3rd party
import React, {useContext} from "react"
import styled from "styled-components"

// context
import {GlobalStateContext, GlobalDispatchContext} from "../../context/GlobalContextProvider"

// constants
import {device, sizes} from "../../constants/css"

// components
import Link from "../general/link"
import BreadcrumbItem from "./breadcrumbItem"
import Breadcrumb from "./breadcrumb"
import BreadcrumbTitle from "./breadcrumbTitle"
import BreadcrumbsMedium from "./breadcrumbsMedium"

function Breadcrumbs({postType, medium, mainProject, child, current, project, mediumParent}) {
  const appDispatch = useContext(GlobalDispatchContext)
  const appState = useContext(GlobalStateContext)
  let attachedToMedium = appState.navData.works.filter(work => work.node.medium.nodes.some(e => e.id === medium?.id))
  let attachedToMediumNumber = current && attachedToMedium.length // only if template is work (see 'current')
  const sep = "&#8226;"
  return (
    <BreadcrumbTitle>
      <Fixed>
        <Ul>
          <BreadcrumbItem>
            <Link uri="/">draax.nl</Link>
          </BreadcrumbItem>
          {postType && (
            <>
              <BreadcrumbItem dangerouslySetInnerHTML={{__html: sep}} />
              <BreadcrumbItem
                className="no-exit"
                onClick={() => appDispatch({type: "setNavType", value: postType})}
                cursor="pointer"
              >
                {postType}
              </BreadcrumbItem>
            </>
          )}
          {postType === "medium" && <BreadcrumbsMedium {...{medium, mediumParent}} number={attachedToMediumNumber} />}
          {mainProject && <Breadcrumb uri={mainProject.uri} name={mainProject.title} />}
          {child?.title && <Breadcrumb uri={child.uri} name={child.title} />}
          {postType === "project" && !!medium?.medium?.singular && (
            <Breadcrumb uri={medium.uri} name={medium.medium.singular} number={attachedToMediumNumber} />
          )}
          {current && <Breadcrumb name={current.title} />}
        </Ul>
      </Fixed>
    </BreadcrumbTitle>
  )
}

const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media ${device.mobileL} {
    margin-left: -30px;
  }
`

const Fixed = styled.div`
  position: fixed;
  padding: 10px 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  border-top: none;
  top: 0;
  left: ${sizes.left.small}px;
  right: 0;
  @media ${device.mobileL} {
    left: ${sizes.left.large}px;
  }
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export default Breadcrumbs
