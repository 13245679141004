// 3rd party
import styled from "styled-components"

// constants
import {device, sizes} from "../../constants/css"

const Container = styled.div`
  overflow: hidden;
  //padding-top: 50px;
  padding-left: ${sizes.left.small}px;
  position: relative;
  padding-top: ${sizes.top.small}px;
  height: calc(var(--vh, 1vh) * 100);
  //height: calc(100vh - ${sizes.top.small}px); /* Fallback for browsers that do not support Custom Properties */
  //height: calc(var(--vh, 1vh) * 100 - ${sizes.top.small}px);
  //width: calc(100% - ${sizes.left.small}px);
  @media ${device.tabletM} {
    padding-top: ${sizes.top.large}px;
    //height: calc(100vh - ${sizes.top.large}px);
    padding-left: ${sizes.left.large}px;
    //width: calc(100% - ${sizes.left.large}px);
  }
`
export default Container
